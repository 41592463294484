<template>
  <div class="self-center">
    <div class="flex-row flex-wrap flex-gap-xs">
      <!-- Edit -->
        <!-- v-b-tooltip.hover.right="'Edit this record'" -->
      <b-button
        v-if="$checkRights(this.$route.meta.section, 'update')"
        class="btn-icon"
        variant="outline-dark"
        @click="editRecord"
      >
        <feather-icon
          class="text-warning"
          icon="EditIcon"
          svg-classes="hover:text-primary stroke-current"
        />
      </b-button>

      <!-- Deacivate -->
      <!-- <b-button
        v-if="$checkRights(this.$route.meta.section, 'delete')"
        v-b-tooltip.hover.top="'Deactivate this user'"
        class="btn-icon small-space"
        variant="outline-dark"
        @click="confirmDeleteRecord"
      >
        <feather-icon
          class="text-danger"
          icon="Trash2Icon"
          svg-classes="hover:text-primary stroke-current"
        />
      </b-button> -->
    </div>
  </div>
</template>

<script>

export default {
  name: 'CellRendererActions',
  props: {
    params: Object,
  },
  data() {
    return {
      isLoading: false,
    }
  },
  methods: {
    viewRecord() {
      this.$emit('view-record', this.params)
    },

    editRecord() {
      if (this.$checkRights(this.$route.meta.section, 'update')) {
        this.$emit('edit-record', this.params)
      } else {
        this.$toast(
          {
            component: this.$toastContent,
            props: {
              title: 'Inadequate permission',
              icon: 'CheckCircleIcon',
              text: 'You dont have enough rights to update data in this section',
              variant: 'danger',
              position: 'center',
            },
          },
          { timeout: this.$longestTimeout },
        )
      }
    },

    confirmDeleteRecord() {
      if (this.$checkRights(this.$route.meta.section, 'update')) {
        this.$bvModal
          .msgBoxConfirm(
            `Are you sure you want to deactivate "${this.params.company_name}"`,
            {
              title: 'Confirm User Deletion',
              size: 'sm',
              okVariant: 'danger',
              okTitle: 'Deactivate',
              cancelTitle: 'Cancel',
              cancelVariant: 'outline-secondary',
              hideHeaderClose: false,
              centered: true,
            },
          )
          .then(value => {
            if (value) {
              this.deleteRecord()
            }
          })
      } else {
        this.$toast(
          {
            component: this.$toastContent,
            props: {
              title: 'Inadequate permission',
              icon: 'CheckCircleIcon',
              text: "You don't have enough rights to deactivate data in this section",
              variant: 'danger',
              position: 'center',
            },
          },
          { timeout: this.$longestTimeout },
        )
      }
    },

    deleteRecord() {
      // const { id } = this.$store.state.auth.ActiveUser || {}
      // if (id === this.params.user_id) {
      //   this.$toastDanger('Operation Failed', 'You cannot deactivate your own account.')
      // } else {
      //   this.showDeleteSuccess()

      //   this.$emit('refresh-record', true)
      //   // this.params.context.componentParent.get_company_users();
      // }

      this.showDeleteSuccess()
    },

    showDeleteSuccess() {
      const { token } = this.$store.state.auth.ActiveUser
      this.$http.defaults.headers.common.Authorization = `Token ${token}`
      this.isLoading = true

      this.$http
        .delete(`/api/companies/${this.params.id}`, {})
        .then(response => {
          const { output, success } = response.data

          if (success) {
            // this.params.context.componentParent.deleteSelected()

            this.$toastSuccess('Account Operations', 'Account deactivated successfully.')

            this.$emit('refresh-record', true)
          } else {
            this.$toastDanger('Account Operations', 'Could not deactivate this company, please try again later')
          }

          this.isLoading = false
        })
        .catch(error => {
          this.$handleErrorResponse(error, 'Account Operations', 'Could not deactivate this company, please try again later')

          this.isLoading = false
        })
    },
  },
}
</script>
<style scoped>
.small-space {
  margin-left: 2px;
  margin-right: 2px;
}
</style>
